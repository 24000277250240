module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[],"injectPageProps":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Michael Lisboa's Portfolio","short_name":"Michael Lisboa","lang":"en","start_url":"/?utm_source=A2HS&utm_campaign=pwa2021","background_color":"#b0bec5","theme_color":"#37474f","display":"standalone","scope":"/","orientation":"portrait","icon":"static/favicons/astro-logo-512.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"cffa6d36489f0517d9027b8022fba168"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/portfolio/*","/profile/*","/my-team/*","/michael-makes-products/*","/products/*"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-137372309-1","head":true,"anonymize":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
