// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-capabilities-js": () => import("./../../../src/pages/capabilities.js" /* webpackChunkName: "component---src-pages-capabilities-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-michael-makes-products-js": () => import("./../../../src/pages/michael-makes-products.js" /* webpackChunkName: "component---src-pages-michael-makes-products-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-products-fintech-currency-transfer-ui-kit-js": () => import("./../../../src/pages/products/fintech-currency-transfer-ui-kit.js" /* webpackChunkName: "component---src-pages-products-fintech-currency-transfer-ui-kit-js" */),
  "component---src-pages-products-payment-success-js": () => import("./../../../src/pages/products/payment-success.js" /* webpackChunkName: "component---src-pages-products-payment-success-js" */),
  "component---src-pages-products-travel-safe-by-michael-js": () => import("./../../../src/pages/products/travel-safe-by-michael.js" /* webpackChunkName: "component---src-pages-products-travel-safe-by-michael-js" */),
  "component---src-pages-profile-js": () => import("./../../../src/pages/profile.js" /* webpackChunkName: "component---src-pages-profile-js" */),
  "component---src-pages-rates-js": () => import("./../../../src/pages/rates.js" /* webpackChunkName: "component---src-pages-rates-js" */),
  "component---src-pages-resume-js": () => import("./../../../src/pages/resume.js" /* webpackChunkName: "component---src-pages-resume-js" */),
  "component---src-templates-blog-detail-template-js": () => import("./../../../src/templates/blog-detail-template.js" /* webpackChunkName: "component---src-templates-blog-detail-template-js" */),
  "component---src-templates-landing-page-template-rich-text-js": () => import("./../../../src/templates/landing-page-template-rich-text.js" /* webpackChunkName: "component---src-templates-landing-page-template-rich-text-js" */),
  "component---src-templates-portfolio-detail-template-js": () => import("./../../../src/templates/portfolio-detail-template.js" /* webpackChunkName: "component---src-templates-portfolio-detail-template-js" */)
}

