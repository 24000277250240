// import React, {useEffect} from "react";

export const onServiceWorkerUpdateReady = () => {
    if(typeof document === "undefined" || typeof window === "undefined") return;

    if(window.innerWidth <=768) {
        const answer = window.confirm(
            `We've updated the website.\nDo you want to see the latest version?`
        )
        if (answer === true) {
            window.location.reload()
        }
    }
}
